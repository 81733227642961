import { Box } from "@mui/material"

export const AppBarPadding = () => {
    return (<Box sx={{pt: 12}}/>)
}
export const BottomPadding = () => {
    return (<Box sx={{ pb: 10 }} />)
}

export const checkSuccess = (response) => {
    return response && response.status >= 200 && response.status < 300;
};

// utils/errorUtils.js
export const formatApiError = (error, defaultMessage = "An error occurred.") => {
    let errorText = defaultMessage + " ";

    if (error.response?.data) {
        const errors = error.response.data;

        if (typeof errors === "string") {
            errorText += ` ${errors}`;
        } else {
            errorText += Object.entries(errors)
                .map(([field, messages]) => `${field}: ${Array.isArray(messages) ? messages.join(", ") : messages}`)
                .join(" | ");
        }
    }

    return errorText;
};