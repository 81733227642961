import React, { createContext, useContext, useState } from "react";

// Create the Context
const AppContext = createContext();

// Custom Hook for easy access
export const useAppContext = () => useContext(AppContext);

// Context Provider Component
export const AppProvider = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    return (
        <AppContext.Provider value={{ menuOpen, toggleMenu }}>
            {children}
        </AppContext.Provider>
    );
};