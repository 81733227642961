import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import logo from "../../assets/logo_primary.png"; // Ensure you have your logo
import { theme } from "../../styles/theme"; // Import your theme
import { AppBarPadding, BottomPadding } from "../../utils/helperUtils";
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

const AboutUs = () => {
    const currentYear = new Date().getFullYear(); // Dynamically get the current year


    return (
        <Box className="view-container" >
            <AppBarPadding />
            {/* Big Logo */}
            <Grid container justifyContent={'center'}>
                <Grid size={{ xs: 12, md: 10, lg: 6 }}>
                    <Grid
                        container
                        justifyContent={'center'}
                        spacing={2}
                        p={2}
                    >
                        <Grid size={{ xs: 12 }}>

                            <Box display="flex" justifyContent="center">
                                <img src={logo} alt="Sidewalk Logo" style={{ width: "250px", height: "auto" }} />
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 12 }}>

                            {/* About Us Text */}
                            <Typography variant="h5"
                                textAlign="center"
                                mt={3}>
                                Sidewalk is made with ❤️ in Toronto 🇨🇦, Los Angeles 🇺🇸, and Jersey City 🇺🇸
                            </Typography>
                        </Grid>
                        {/* Buttons for Website & Instagram */}
                        <Grid size={{ xs: 12 }} container justifyContent={'center'} mt={3}> 
                            <Button
                                variant="contained"
                                size="large"
                                startIcon={<EmailIcon />}
                                sx={{
                                    bgcolor: theme.colors.primary,
                                    borderRadius: 5,
                                    px: 3,
                                    "&:hover": { bgcolor: theme.colors.primaryDark },
                                }}
                                href="mailto:hello@sidewa.lk"
                                target="_blank"
                            >
                                Email Us
                            </Button>
                        </Grid>
                        <Grid size={{ xs: 12 }} container justifyContent={'center'}> 
                            <Button
                                variant="contained"
                                size="large"
                                startIcon={<InstagramIcon />}
                                sx={{
                                    bgcolor: "#E1306C",
                                    color: "white",
                                    borderRadius: 5,
                                    px: 3,
                                    "&:hover": { bgcolor: "#a72d6f" },
                                }}
                                href="https://instagram.com/getsidewa.lk"
                                target="_blank"
                            >
                                Follow on Instagram
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Typography variant="body2" color="textSecondary" mt={5} textAlign={'center'}>
                            © {currentYear} Sidewalk
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>

            <BottomPadding />
        </Box>
    );
};

export default AboutUs;