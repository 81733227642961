import { createTheme } from "@mui/material/styles";

export const theme = {
    colors: {
        primary: "#005F73",
        primaryLight: "#0A9396",
        secondary: "#EE9B00",
        secondaryDark: "#885053",
        background: "white",
        black: "#001219",
        yellow: "#EE9B00",
        red: "#AE2012",
        events: {
            primary: "#4A7C59",
            primaryDark: "#283618",
            secondary: "#F7EFDA",
            black: "#050F00",
            background: "#FFFFEB"
        }
    },
    fonts: {
        regular: "OpenSans-Regular",
        italic: "OpenSans-Italic",
        bold: "OpenSans-Bold",
    },
};

export const muiTheme = createTheme({
    typography: {
        fontFamily: '"OpenSans", sans-serif',
    },
    palette: {
        primary: { main: theme.colors.primary },
        secondary: { main: theme.colors.secondary },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize", // Capitalize only the first letter
                },
            },
        },
    }
});
