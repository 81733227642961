import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../../styles/theme';

function ManageEventsCardComponent() {
    return (
        <Accordion
            square={true}
            sx={{
                borderRadius: 5,
                overflow: "hidden",
                "&:before": { display: "none" } // Removes the default divider line
            }}
        >
            {/* Accordion Header */}
            <AccordionSummary
            sx={{
            }}
                expandIcon={<ExpandMoreIcon className='manage-card-icon-circle' sx={{ color: theme.colors.events.primary }} />}>
                <Box>
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography variant='h6' color={theme.colors.events.black} sx={{ display: "inline" }}>
                            Monday Market
                        </Typography>
                        <Chip label="PENDING" variant="outlined" size="small"
                            sx={{ color: theme.colors.red, borderColor: theme.colors.red, ml: 1 }} />
                    </Box>
                    <Typography variant='body2' color={'textSecondary'}>
                        November 13, 2025 at 9:00 PM
                    </Typography>
                </Box>
            </AccordionSummary>
            {/* Accordion Content */}
            <AccordionDetails sx={{ py: 0, mt: -2 }}>
                <List>
                    <ListItemButton onClick={() => alert("Edit Event clicked")}>
                        <ListItemText primary="✏️" sx={{ maxWidth: "35px" }} />
                        <ListItemText primary="Edit Event" />
                    </ListItemButton>

                    <ListItemButton>
                        <ListItemText primary="✅" sx={{ maxWidth: "35px" }} />
                        <ListItemText primary="Approve Vendors" />
                    </ListItemButton>

                    <ListItemButton>
                        <ListItemText primary="👀" sx={{ maxWidth: "35px" }} />
                        <ListItemText primary="Preview Event" />
                    </ListItemButton>

                    <ListItemButton>
                        <ListItemText primary="💣" sx={{ maxWidth: "35px" }} />
                        <ListItemText primary="Delete Event" />
                    </ListItemButton>
                </List>
            </AccordionDetails>
        </Accordion>
    );
}

export default ManageEventsCardComponent;