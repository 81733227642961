import { Box, Divider, Fab, Tooltip, Typography } from '@mui/material'
import React from 'react'
import "../../styles/events.css"; // Import CSS
import { theme } from '../../styles/theme';
import ManageEventsCardComponent from './components/ManageEventsCardComponent';
import Grid from '@mui/material/Grid2';
import { AddCircleRounded } from '@mui/icons-material';
import { cardGridSize } from '../../utils/constants';
import { AppBarPadding, BottomPadding } from '../../utils/helperUtils';

function ManageEventsView() {

  const FloatingActionButton = () => {
    const handleClick = (e) => {
      e?.preventDefault();
      alert("Create New Event clicked");
    };

    return (
      <Tooltip title="Create New Event" arrow>
        <Fab
          variant="extended"
          // color={theme.colors.events.secondary}
          onClick={(e) => handleClick(e)}
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            backgroundColor: theme.colors.events.secondary,
            color: theme.colors.events.primaryDark,
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: theme.colors.events.primary,
            },
          }}
        >
          <AddCircleRounded sx={{ mr: 1 }} />
          Create New Event
        </Fab>
      </Tooltip>
    );
  };

  return (
    <Box className="events-box-container" bgcolor={theme.colors.events.primary}>
      <AppBarPadding />
      <Grid container justifyContent={'center'}>
        <Grid size={{md: 12, lg: 6}}>
          <Typography fontSize={30} color={theme.colors.events.secondary}>
            <Box sx={{ fontWeight: 'bold', mb: 1 }}>
              Your Events 🗓️
            </Box>
          </Typography>
          <Divider sx={{ borderBottomWidth: 2, bgcolor: theme.colors.events.secondary }} />
          <Grid container
            spacing={2}
            mt={2}
          >
            <Grid size={cardGridSize}>
              <ManageEventsCardComponent />
            </Grid>
            <Grid size={cardGridSize}>
              <ManageEventsCardComponent />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FloatingActionButton />
      <BottomPadding />
    </Box>
  )
}

export default ManageEventsView