import { Alert, Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Using GridV2
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../../App.css";
import { registerUser } from '../../../services/authServices';
import { theme } from '../../../theme';
import { AppBarPadding, BottomPadding, checkSuccess } from '../../../utils/helperUtils';
import CustomInput from '../../components/CustomComponents';
import GoogleLoginComponent from '../../components/GoogleLoginComponent';
import { useSnackbar } from '../../../context/SnackbarContext';

function SignUpView() {


  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  // State for registration
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Handle Input Changes
  const handleChange = (field) => (value) => {
    setUserDetails((prev) => ({ ...prev, [field]: value }));
  };

  // Handle Registration
  const handleSignUp = async () => {
    setLoading(true);
    setError(null);

    // Basic validation
    if (userDetails.password !== userDetails.confirm_password) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      const response = await registerUser({
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: userDetails.email,
        password: userDetails.password,
      });

      if (checkSuccess(response)) {
        showSnackbar("🎉 Account Created! Please Sign in again", "success");
        navigate("/_/login");
      } else {
        setError(response.error || "Registration failed. Please try again.");
      }
    } catch (err) {
      setError(err.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const inputFields = [
    { label: "First Name", type: "text", placeholder: "First Name (Required)", key: "first_name" },
    { label: "Last Name", type: "text", placeholder: "Last Name (Required)", key: "last_name" },
    { label: "Email Address", type: "email", placeholder: "Email (Required)", key: "email" },
    { label: "Password", type: "password", placeholder: "Enter your password", key: "password" },
    { label: "Confirm Password", type: "password", placeholder: "Confirm your password", key: "confirm_password" },
  ];

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}>

      <Box className="view-container" bgcolor={theme.colors.secondary}>
        <AppBarPadding />
        <Grid container justifyContent={'center'}>
          <Grid size={{ xs: 12, md: 10, lg: 6 }}>
            <Grid
              container
              spacing={2}
              bgcolor={theme.colors.background}
              p={2}
              borderRadius={5}
            >
              <Grid size={{ xs: 12 }}>
                <Typography fontSize={30} ml={1}>
                  <Box sx={{ fontWeight: 'bold', }}>
                    Join the Squad! ✨
                  </Box>
                </Typography>
              </Grid>

              {/* Show error alert if registration fails */}
              {error && (
                <Grid size={{ xs: 12 }}>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              )}
              {
                inputFields.map(({ label, type, placeholder, key }, index) => (
                  <Grid key={key} size={{ xs: 12, md: key === "first_name" || key === "last_name" ? 6 : 12 }}>
                    <CustomInput
                      label={label}
                      type={type}
                      placeholder={placeholder}
                      value={userDetails[key]}
                      onChange={handleChange(key)}
                    />
                  </Grid>
                ))
              }
              <Grid size={{ xs: 12 }}>
                <Button
                  variant='contained'
                  fullWidth
                  size='large'
                  sx={{ borderRadius: 5, color: 'white', bgcolor: theme.colors.secondaryDark }}
                  onClick={handleSignUp}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Create Account 🚀"}
                </Button>
              </Grid>
            </Grid>

            {/* Google Sign-In Button */}
            <Grid mt={2}>
              <GoogleLoginComponent
                loading={loading}
                setLoading={setLoading}
                setError={setError}
              />
            </Grid>

            {/* Divider */}
            <Grid>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "white",
                  },
                  color: 'white',
                  my: 2
                }}
              >
                OR
              </Divider>
            </Grid>

            {/* Log In Button */}
            <Grid>
              <Button
                variant='contained'
                fullWidth
                size='large'
                sx={{ borderRadius: 5, color: 'white', bgcolor: theme.colors.primary, }}
                onClick={() => navigate("/_/login")}
              >
                Log In 💎
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <BottomPadding />
      </Box>
    </GoogleOAuthProvider >

  )
}

export default SignUpView