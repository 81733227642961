import { theme } from "../theme";

export const createEventInputSX = {
    input: { color: theme.colors.events.secondary }, // Changes text color
    label: { color: theme.colors.events.secondary }, // Changes label color
    "& .MuiInputLabel-root.Mui-focused": { color: theme.colors.events.secondary }, // Floating label when focused
    "& .MuiInput-underline:before": { borderBottomColor: theme.colors.events.secondary }, // Default underline color
    "& .MuiInput-underline:hover:before": { borderBottomColor: theme.colors.events.primaryDark }, // Hover color
    "& .MuiInput-underline:after": { borderBottomColor: theme.colors.events.secondary }, // Focus color,
    "& .MuiInputBase-inputMultiline": { color: theme.colors.events.secondary }, // Multiline text color
    "& .MuiSelect-select": { color: theme.colors.events.secondary,}, // Selected value color
    "& .MuiSvgIcon-root": { color: theme.colors.events.secondary }, // Dropdown arrow color
    width: '100%'
}

export const ACCESS_TOKEN_KEY = "access_token";
export const REFRESH_TOKEN_KEY = "refresh_token";

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const cardGridSize = { xs: 12 }