import { Button, CircularProgress } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import googleIcon from '../../assets/google_logo.png';
import { useAuth } from "../../context/AuthContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { googleSignInService } from '../../services/authServices';
import { checkSuccess } from "../../utils/helperUtils";

const GoogleLoginComponent = ({ loading, setLoading, setError, from, sx, ...props }) => {

    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    const { login } = useAuth();

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setLoading(true);
            setError(null);

            try {
                // Extract Google access token
                const googleAccessToken = tokenResponse.access_token;

                // Call API to authenticate with backend
                const apiResponse = await googleSignInService({ google_access_token: googleAccessToken }, login);

                if (checkSuccess(apiResponse)) {
                    // Redirect user to dashboard after login
                    showSnackbar("🎉 Login successful!", "success");
                    navigate(from, { replace: true });
                } else {
                    throw new Error(apiResponse.error || "Google Sign-in failed");
                }
            } catch (err) {
                setError(err.message || "Google Sign-in failed");
                console.error("Google Login Error:", err);
            } finally {
                setLoading(false);
            }
        },
        onError: () => setError("Google Sign-in failed"),
    });

    return (
        <Button
            variant="contained"
            startIcon={<img src={googleIcon} style={{ height: "1.2rem" }} />}
            fullWidth
            size="large"
            sx={{
                borderRadius: 5, bgcolor: 'white', color: 'gray',
                ...sx, // Allows overriding styles
            }}
            onClick={handleGoogleLogin}
            disabled={loading}
            {...props}
        >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Continue with Google"}
        </Button>
    );
};

export default GoogleLoginComponent;