import React from "react";

import { IconButton } from "@mui/material";
import appStoreIcon from "../../assets/appStore.png";
import googlePlayIcon from "../../assets/googlePlay.png";
import Grid from '@mui/material/Grid2';


const storeLinks = {
  appStore: "https://apps.apple.com/us/app/sidewalk/id6708225418",
  googlePlay: "https://play.google.com/store/apps/details?id=com.sidewalk.sidewalk",
};
const GetStarted = () => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {/* App Store Button */}
      <Grid size={{xs: 12}} >
        <IconButton
          component="a"
          href={storeLinks.appStore}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ p: 0 }}
        >
          <img src={appStoreIcon} alt="Download on the App Store" style={{ width: 200, height: "auto" }} />
        </IconButton>
      </Grid>

      {/* Google Play Store Button */}
      <Grid size={{ xs: 12 }}>
        <IconButton
          component="a"
          href={storeLinks.googlePlay}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ p: 0 }}
        >
          <img src={googlePlayIcon} alt="Get it on Google Play" style={{ width: 200, height: "auto" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default GetStarted;
