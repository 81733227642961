import React from "react";
import { motion } from "framer-motion";

export function HelloWave() {
    return (
        <motion.div
            animate={{
                rotate: [0, 25, 0], // Animates from 0° → 25° → 0°
            }}
            transition={{
                duration: 0.15, // Time per animation cycle
                repeat: 4, // Repeat 4 times
                repeatType: "loop", // Loop the animation
            }}
            style={{
                display: "inline-block",
            }}
        >
            <span style={{ fontSize: "28px", lineHeight: "32px" }}>👋</span>
        </motion.div>
    );
}