import React from "react";
import "react-device-frameset/styles/marvel-devices.min.css";
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "../../App.css";

import {
  Box,
  Container,
  Typography
} from "@mui/material";
import iphone_mockup from "../../assets/Iphone_mockup_v4.png";
import GetStarted from "../components/GetStartedComponent";

const HomeView = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (

    <div className="App">
      <Container maxWidth="xl">
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              mt={4}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                size={{ xs: 12, md: 6 }}
              >
                <>
                  <Typography
                    variant={isMobile ? "h4" : "h3"}
                    mt={isMobile ? 6 : 3}
                    sx={{color: "#212427"}}
                  >
                    The best vendors are on Sidewalk.
                  </Typography>
                  <Typography
                    variant={isMobile ? "subtitle1" : "h6"}
                    mt={3}
                    sx={{
                      color: "#212427",
                    }}>
                    Live location sharing makes it easy for customers to find you. Post photos, collect reviews, and keep them coming back for more — all from your Sidewalk link.
                  </Typography>
                </>
                <br />
                <GetStarted />
              </Grid>
              <Grid
                item
                size={{ xs: 12, md: 6 }}
                padding={2}
              >
                <img
                  alt="iphone_mockup"
                  src={iphone_mockup}
                  className="iphone_mockup"
                  style={{ width: "100%", height: "auto", maxWidth: "370px" }}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      </Container>
    </div>
  );
};

export default HomeView;
