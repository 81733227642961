import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    Divider,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import Grid from '@mui/material/Grid2'; // Using GridV2
import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { theme } from "../../theme";
import { createEventInputSX } from "../../utils/constants";
import { AppBarPadding, BottomPadding } from '../../utils/helperUtils';


const steps = [
    "🎊 Let’s Kick This Off!",
    "🕰 Tick-Tock, Pick a Slot!",
    "🔗 Drop Your Links!",
    "📸 Proof It’s Real – Upload Pics!",
    "🎟 Ticket to Ride – Set Your Price!",
    "🎯 Final Check – All Set?"
];

const socialMediaOptions = [
    { label: "Instagram", value: "instagram" },
    { label: "Twitter", value: "twitter" },
    { label: "TikTok", value: "tiktok" }
];

export default function CreateEventsView() {
    const themeX = useTheme();
    const maxSteps = steps.length;

    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        eventName: "",
        eventSlug: "",
        eventSummary: "",
        eventLocation: "",
        startDate: null,
        eventTime: null, // Keep time
        organizationName: "",
        socialMediaLinks: [],
        bannerPhoto: null,  // Only 1 Banner Photo
        eventPhotos: [],
        pricing: [],
        additionalInfo: "",
    });
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const [lightboxImages, setLightboxImages] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const validateStep = () => {
        switch (activeStep) {
            case 0: // Step 1: Event Details
                return (
                    formData.eventName.trim() !== "" &&
                    formData.eventSummary.trim() !== "" &&
                    formData.eventLocation.trim() !== ""
                );
            case 1: // Step 2: Date & Time
                return formData.startDate !== null && formData.eventTime !== null;
            case 2: // Step 3: Organizer Info (Optional, but validate if social media exists)
                return formData.socialMediaLinks.length === 0 ||
                    formData.socialMediaLinks.every(
                        (social) => social.platform.trim() !== "" && social.username.trim() !== ""
                    );
            case 3: // Step 4: Photo Upload (No required fields)
                return true;
            case 4: // Step 5: Pricing & Slots (At least 1 pricing entry required)
                return formData.pricing.length === 0 || formData.pricing.length > 0 &&
                    formData.pricing.every(
                        (price) => price.boothSize.trim() !== "" && price.slots !== "" && price.cost !== ""
                    );
            case 5: // Step 6: Summary (Always Valid)
                return true;
            default:
                return false;
        }
    };

    const handleAddSocialMedia = () => {
        if (formData.socialMediaLinks.length < 3) {
            setFormData({
                ...formData,
                socialMediaLinks: [...formData.socialMediaLinks, { platform: "", username: "" }]
            });
        }
    };

    const handleChangeSocialMedia = (index, field, value) => {
        const updatedLinks = [...formData.socialMediaLinks];
        updatedLinks[index][field] = value;
        setFormData({ ...formData, socialMediaLinks: updatedLinks });
    };

    const handleRemoveSocialMedia = (index) => {
        const updatedLinks = formData.socialMediaLinks.filter((_, i) => i !== index);
        setFormData({ ...formData, socialMediaLinks: updatedLinks });
    };

    const handleAddPricing = () => {
        if (formData.pricing.length < 3) {
            setFormData({
                ...formData,
                pricing: [...formData.pricing, { boothSize: "", slots: "", cost: "" }]
            });
        }
    };

    const handleChangePricing = (index, field, value) => {
        const updatedPricing = [...formData.pricing];
        updatedPricing[index][field] = value;
        setFormData({ ...formData, pricing: updatedPricing });
    };

    const handleRemovePricing = (index) => {
        const updatedPricing = formData.pricing.filter((_, i) => i !== index);
        setFormData({ ...formData, pricing: updatedPricing });
    };

    const handleBannerUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData({ ...formData, bannerPhoto: URL.createObjectURL(file) });
        }
    };

    const handleEventPhotoUpload = (event) => {
        const files = Array.from(event.target.files);
        console.log({ files })
        if (formData.eventPhotos.length + files.length <= 6) {
            const newPhotos = files.map((file) => URL.createObjectURL(file));
            console.log({ newPhotos })
            setFormData({ ...formData, eventPhotos: [...formData.eventPhotos, ...newPhotos] });
        }
    };

    const handleRemovePhoto = (type, index) => {
        if (type === "banner") {
            setFormData({ ...formData, bannerPhoto: null });
        } else {
            const updatedPhotos = formData.eventPhotos.filter((_, i) => i !== index);
            setFormData({ ...formData, eventPhotos: updatedPhotos });
        }
    };

    const handlePreviewPhoto = (index, type) => {
        let images = [];
        if (type === "banner") {
            images = [{ src: formData.bannerPhoto }];
        } else {
            images = formData.eventPhotos.map((photo) => ({ src: photo }));
        }

        setLightboxImages(images);
        setLightboxIndex(index);
        setLightboxOpen(true);
    };

    // Handle input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Handle Date & Time selection
    const handleDateChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    // Navigation
    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    // Submit form
    const handleSubmit = () => {
        console.log("Form Data Submitted:", formData);
    };

    return (
        <>
            <Box className="events-box-container" bgcolor={theme.colors.events.seconbdary}>
                <AppBarPadding />
                <Grid container justifyContent={'center'}>
                    <Grid>
                        <Typography fontSize={30} color={theme.colors.events.primaryDark}>
                            <Box sx={{ fontWeight: 'bold', mb: 1 }}>
                                Create a new Event 🚀
                            </Box>
                        </Typography>
                        <Divider sx={{ borderBottomWidth: 2, bgcolor: theme.colors.events.secondary }} />
                        <Box
                            sx={{
                                mt: 2,
                                bgcolor: theme.colors.events.primary
                            }}
                            borderRadius={4}
                        >
                            {/* Stepper Header */}
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: 'center',
                                    pt: 2,
                                    pb: 1
                                }}
                            >
                                <Typography variant="h6" sx={{ color: theme.colors.events.secondary, fontWeight: 'bold' }}>{steps[activeStep]}</Typography>
                            </Box>

                            {/* Step Content */}
                            <Box sx={{ paddingX: 3, pb: 2 }}>
                                {/* STEP 1: Event Details */}
                                {activeStep === 0 && (
                                    <Grid container spacing={2}>
                                        <Grid size={{ xs: 12, lg: 6 }}>
                                            <TextField
                                                fullWidth
                                                required
                                                label="Event Name"
                                                name="eventName"
                                                value={formData.eventName}
                                                onChange={handleChange}
                                                variant="standard"
                                                sx={createEventInputSX}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 12, lg: 6 }}>
                                            <TextField
                                                fullWidth
                                                label="Event Handle (Optional)"
                                                name="eventSlug"
                                                value={formData.eventSlug}
                                                onChange={handleChange}
                                                variant="standard"
                                                sx={createEventInputSX}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton size="small" onClick={handleOpen}>
                                                                <InfoOutlinedIcon fontSize="small" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 12, lg: 6 }}>
                                            <TextField
                                                fullWidth
                                                required
                                                label="Event Summary"
                                                name="eventSummary"
                                                multiline
                                                maxRows={3}
                                                value={formData.eventSummary}
                                                onChange={handleChange}
                                                variant="standard"
                                                sx={createEventInputSX}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 12, lg: 6 }}>
                                            <TextField
                                                fullWidth
                                                required
                                                label="Event Location"
                                                name="eventLocation"
                                                value={formData.eventLocation}
                                                onChange={handleChange}
                                                variant="standard"
                                                sx={createEventInputSX}
                                            />
                                        </Grid>
                                    </Grid>
                                )}

                                {/* STEP 2: Date & Time */}
                                {activeStep === 1 && (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                            <Grid size={{ xs: 12, lg: 6 }} md={6}>
                                                <DatePicker
                                                    label="Event Date"
                                                    value={formData.startDate}
                                                    onChange={(value) => handleDateChange("startDate", value)}
                                                    sx={createEventInputSX}
                                                    slotProps={{ textField: { variant: "standard" } }}
                                                />
                                            </Grid>
                                            <Grid size={{ xs: 12, lg: 6 }}>
                                                <TimePicker
                                                    label="Event Time"
                                                    value={formData.eventTime}
                                                    onChange={(value) => handleDateChange("eventTime", value)}
                                                    sx={{
                                                        ...createEventInputSX,
                                                    }}
                                                    slotProps={{ textField: { variant: "standard" } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </LocalizationProvider>
                                )}

                                {/* STEP 3: Organizer Info */}
                                {activeStep === 2 && (
                                    <Grid container spacing={2} sx={{ mt: 2 }}>
                                        <Grid size={{ xs: 12, lg: 6 }}>
                                            <TextField
                                                fullWidth
                                                label="Organization Name (Optional)"
                                                name="organizationName"
                                                value={formData.organizationName}
                                                onChange={handleChange}
                                                variant="standard"
                                                sx={createEventInputSX}
                                            />
                                        </Grid>
                                        {/* Social Media Inputs */}
                                        {formData.socialMediaLinks.map((social, index) => (
                                            <Grid container key={index} spacing={2} sx={{ mt: 1 }}>
                                                <Grid size={{ xs: 6, lg: 4 }}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        label="Platform"
                                                        value={social.platform}
                                                        onChange={(e) => handleChangeSocialMedia(index, "platform", e.target.value)}
                                                        variant="standard"
                                                        sx={createEventInputSX}
                                                    >
                                                        {socialMediaOptions.map((option) => (
                                                            <MenuItem key={option.value} value={option.value} color="primary"
                                                                sx={{ color: theme.colors.events.primaryDark }} >
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid size={{ xs: 6, lg: 4 }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Username"
                                                        value={social.username}
                                                        onChange={(e) => handleChangeSocialMedia(index, "username", e.target.value)}
                                                        variant="standard"
                                                        sx={createEventInputSX}
                                                    />
                                                </Grid>
                                                <Grid size={{ xs: 12, lg: 2 }} display="flex" alignItems="center">
                                                    <Button onClick={() => handleRemoveSocialMedia(index)} sx={{ color: theme.colors.events.primaryDark }} startIcon={<CloseIcon />}>
                                                        Remove
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        ))}

                                        {/* Add Social Media Button */}
                                        {formData.socialMediaLinks.length < 3 && (
                                            <Grid size={{ xs: 12 }}>
                                                <Button onClick={handleAddSocialMedia} variant="outlined" color="secondary" component="label" sx={{ borderRadius: 4 }} size="small">
                                                    + Add Social Media
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}

                                {/* STEP 4: Photo Upload */}
                                {activeStep === 3 && (
                                    <Grid container spacing={2} sx={{ mt: 2 }}>
                                        {/* Banner Photo Section */}
                                        <Grid size={{ xs: 12 }}>
                                            <Box >
                                                <Typography color={theme.colors.events.secondary}>
                                                    Banner Photo (1 Max)
                                                </Typography>
                                                <Divider sx={{ bgcolor: theme.colors.events.secondary }} />
                                            </Box>
                                        </Grid>
                                        <Grid size={{ xs: 12 }}>
                                            {formData.bannerPhoto ? (
                                                <Card sx={{ position: "relative", height: 100, display: "inline-block", my: 1 }}>
                                                    <img
                                                        src={formData.bannerPhoto}
                                                        alt="Banner"
                                                        style={{ width: "100%", height: "100%", objectFit: "contain", borderRadius: 8 }}
                                                        onClick={() => handlePreviewPhoto(0, "banner")}
                                                    />
                                                    <IconButton
                                                        sx={{ position: "absolute", top: 0, right: 0, background: "rgba(255, 255, 255, 0.6)" }}
                                                        onClick={() => handleRemovePhoto("banner")}
                                                    >
                                                        <CloseIcon sx={{ color: theme.colors.events.primaryDark }} />
                                                    </IconButton>
                                                </Card>
                                            ) : (
                                                <Button variant="outlined" color="secondary" component="label" sx={{ borderRadius: 4 }} size="small">
                                                    + Upload Banner
                                                    <input type="file" hidden accept="image/*" onChange={handleBannerUpload} />
                                                </Button>
                                            )}
                                        </Grid>

                                        {/* Event Photos Section */}
                                        <Grid size={{ xs: 12 }}>
                                            <Box >
                                                <Typography color={theme.colors.events.secondary}>
                                                    Event Photos (Up to 6)
                                                </Typography>
                                                <Divider sx={{ bgcolor: theme.colors.events.secondary }} />
                                            </Box>
                                        </Grid>
                                        {formData.eventPhotos.length > 0 &&
                                            <Grid size={{ xs: 12 }} container spacing={1}>
                                                {formData.eventPhotos.map((photo, index) => (
                                                    <Grid
                                                        size={{ xs: 4, lg: 2, md: 3 }} // Ensures 3 images per row
                                                        key={index}
                                                    >
                                                        <Card
                                                            sx={{
                                                                position: "relative",
                                                                width: "100%", // Takes full width of its container
                                                                paddingTop: "100%", // Ensures a 1:1 square aspect ratio
                                                                overflow: "hidden",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {/* Image Container */}
                                                            <Box
                                                                sx={{
                                                                    position: "absolute",
                                                                    top: 0,
                                                                    left: 0,
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <img
                                                                    src={photo}
                                                                    alt={`Event ${index}`}
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        objectFit: "contain",
                                                                    }}
                                                                    onClick={() => handlePreviewPhoto(index)}
                                                                />
                                                            </Box>

                                                            {/* Delete Button */}
                                                            <IconButton
                                                                sx={{
                                                                    position: "absolute",
                                                                    top: 3,
                                                                    right: 3,
                                                                    background: "rgba(255, 255, 255, 0.7)",
                                                                    boxShadow: 2,
                                                                    width: '2rem',
                                                                    height: '2rem'
                                                                }}
                                                                size="small"
                                                                onClick={() => handleRemovePhoto("event", index)}
                                                            >
                                                                <CloseIcon sx={{ color: theme.colors.events.primaryDark, fontSize: 20 }} />
                                                            </IconButton>
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        }
                                        {/* Upload More Event Photos */}
                                        {formData.eventPhotos.length < 6 && (
                                            <Grid size={{ xs: 12 }}>
                                                {/* Upload More Event Photos */}

                                                <Button variant="outlined" color="secondary" component="label" sx={{ borderRadius: 4 }} size="small">
                                                    + Upload Event Photos
                                                    <input type="file" hidden accept="image/*" multiple onChange={handleEventPhotoUpload} />
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}

                                {/* STEP 5: Pricing & Slots */}
                                {activeStep === 4 && (
                                    <Grid container spacing={2} sx={{ mt: 2 }}>
                                        {formData.pricing.map((price, index) => (
                                            <Grid container key={index} spacing={2} sx={{ mt: 1 }}>
                                                <Grid size={{ xs: 12, lg: 4 }}>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        label="Booth Size"
                                                        value={price.boothSize}
                                                        onChange={(e) => handleChangePricing(index, "boothSize", e.target.value)}
                                                        variant="standard"
                                                        sx={createEventInputSX}
                                                    />
                                                </Grid>
                                                <Grid size={{ xs: 12, lg: 3 }}>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        label="Number of Slots"
                                                        type="number"
                                                        value={price.slots}
                                                        onChange={(e) => handleChangePricing(index, "slots", e.target.value)}
                                                        variant="standard"
                                                        sx={createEventInputSX}
                                                    />
                                                </Grid>
                                                <Grid size={{ xs: 12, lg: 3 }}>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        label="Cost"
                                                        type="number"
                                                        value={price.cost}
                                                        onChange={(e) => handleChangePricing(index, "cost", e.target.value)}
                                                        variant="standard"
                                                        sx={createEventInputSX}
                                                    />
                                                </Grid>
                                                <Grid size={{ xs: 12, lg: 2 }} display="flex" alignItems="center">
                                                    <Button onClick={() => handleRemovePricing(index)} sx={{ color: theme.colors.events.primaryDark }} startIcon={<CloseIcon />}>
                                                        Remove
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        ))}

                                        {/* Add Pricing Entry Button */}
                                        {formData.pricing.length < 3 && (
                                            <Grid size={{ xs: 12 }}>
                                                <Button onClick={handleAddPricing} variant="outlined" color="secondary" component="label" sx={{ borderRadius: 4 }} size="small">
                                                    + Add Pricing Option
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}

                                {/* STEP 6: Summary */}
                                {activeStep === 5 && (
                                    <Box sx={{mt: 1}}>
                                        {/* Event Details */}
                                        <Accordion defaultExpanded
                                            square={true}
                                            sx={{
                                                borderRadius: 3, // Rounded corners
                                                mb: 2, // Space between each accordion
                                                "&:before": { display: "none" }, // Removes the default divider line
                                                overflow: "hidden", // Ensures rounded corners are applied properly
                                            }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className='manage-card-icon-circle' sx={{ color: theme.colors.events.primary }} />}>
                                                <Typography sx={{ fontWeight: "bold" }}>🎊 Event Details</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ pt: 0 }}>
                                                <Typography><b>Event Name:</b> {formData.eventName}</Typography>
                                                <Typography><b>Event Handle:</b> {formData.eventSlug || "N/A"}</Typography>
                                                <Typography><b>Summary:</b> {formData.eventSummary}</Typography>
                                                <Typography><b>Location:</b> {formData.eventLocation}</Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        {/* Date & Time */}
                                        <Accordion
                                            sx={{
                                                borderRadius: 3, // Rounded corners
                                                mb: 2, // Space between each accordion
                                                "&:before": { display: "none" }, // Removes the default divider line
                                                overflow: "hidden", // Ensures rounded corners are applied properly
                                            }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon className='manage-card-icon-circle' sx={{ color: theme.colors.events.primary }} />}>
                                                <Typography sx={{ fontWeight: "bold" }}>🕰 Date & Time</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ pt: 0 }}>
                                                <Typography><b>Start Date:</b> {formData.startDate?.format("MMMM D, YYYY") || "N/A"}</Typography>
                                                <Typography><b>Time:</b> {formData.eventTime?.format("hh:mm A") || "N/A"}</Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        {/* Social Media */}
                                        <Accordion
                                            sx={{
                                                borderRadius: 3, // Rounded corners
                                                mb: 2, // Space between each accordion
                                                "&:before": { display: "none" }, // Removes the default divider line
                                                overflow: "hidden", // Ensures rounded corners are applied properly
                                            }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon className='manage-card-icon-circle' sx={{ color: theme.colors.events.primary }} />}>
                                                <Typography sx={{ fontWeight: "bold" }}>🔗 Social Media</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ pt: 0 }}>
                                                <Typography><b>Organization Name:</b> {formData.organizationName || "N/A"}</Typography>
                                                {formData.socialMediaLinks.length > 0 ? (
                                                    formData.socialMediaLinks.map((social, index) => (
                                                        <Typography key={index}>
                                                            <b>{social.platform}:</b> @{social.username} {" "}🌍
                                                        </Typography>
                                                    ))
                                                ) : (
                                                    <Typography>No social media links added. 💔</Typography>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>

                                        {/* Photos */}
                                        <Accordion
                                            sx={{
                                                borderRadius: 3, // Rounded corners
                                                mb: 2, // Space between each accordion
                                                "&:before": { display: "none" }, // Removes the default divider line
                                                overflow: "hidden", // Ensures rounded corners are applied properly
                                            }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon className='manage-card-icon-circle' sx={{ color: theme.colors.events.primary }} />}>
                                                <Typography sx={{ fontWeight: "bold" }}>📸 Uploaded Photos</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ pt: 0 }}>
                                                {formData.bannerPhoto ? (
                                                    <Typography><b>Banner Photo:</b> Uploaded ✅</Typography>
                                                ) : (
                                                    <Typography>No banner photo uploaded. 😭</Typography>
                                                )}
                                                {formData.eventPhotos.length > 0 ? (
                                                    <Typography><b>Event Photos:</b> {formData.eventPhotos.length} uploaded ✅</Typography>
                                                ) : (
                                                    <Typography>No event photos uploaded. 😭</Typography>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>

                                        {/* Pricing & Slots */}
                                        <Accordion
                                            square={true}
                                            sx={{
                                                borderRadius: 3, // Rounded corners
                                                mb: 2, // Space between each accordion
                                                "&:before": { display: "none" }, // Removes the default divider line
                                                overflow: "hidden", // Ensures rounded corners are applied properly
                                            }}>

                                            <AccordionSummary expandIcon={<ExpandMoreIcon className='manage-card-icon-circle' sx={{ color: theme.colors.events.primary }} />}>
                                                <Typography sx={{ fontWeight: "bold" }}>🎟 Pricing & Slots</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ pt: 0 }}>
                                                {formData.pricing.length > 0 ? (
                                                    formData.pricing.map((price, index) => (
                                                        <Typography key={index}>
                                                            <b>Booth Size:</b> {price.boothSize},
                                                            <b> Slots:</b> {price.slots},
                                                            <b> Cost:</b> ${price.cost}
                                                            {" "}💸
                                                        </Typography>
                                                    ))
                                                ) : (
                                                    <Typography>No pricing details added. This event is Free. 🫶🏼</Typography>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                )}
                            </Box>

                            {/* Mobile Stepper Navigation */}
                            <MobileStepper
                                variant="text"
                                sx={{
                                    borderBottomLeftRadius: 15,
                                    borderBottomRightRadius: 15,
                                    bgcolor: theme.colors.events.primary,
                                    color: theme.colors.events.secondary,
                                    pb: 2
                                }}
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}

                                nextButton={
                                    <Button
                                        size="small"
                                        onClick={activeStep === maxSteps - 1 ? handleSubmit : handleNext}
                                        sx={{ color: theme.colors.events.secondary }}
                                        disabled={!validateStep()}
                                    >
                                        {activeStep === maxSteps - 1 ? "Submit" : "Next"}
                                        {themeX.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}
                                        sx={{ color: theme.colors.events.primaryDark }}
                                    >
                                        {themeX.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                        Back
                                    </Button>
                                }
                            />
                        </Box>
                    </Grid>
                </Grid>
                <BottomPadding />
            </Box>
            <Lightbox
                open={lightboxOpen}
                close={() => setLightboxOpen(false)}
                slides={lightboxImages}
                index={lightboxIndex}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                sx={{ "& .MuiPaper-root": { padding: 2, maxWidth: 300 } }} // Style for better appearance
            >
                <Typography variant="body2">
                    Event Handle is a short, unique URL identifier for your event.
                    Example: If your event is <Box fontWeight='700' display='inline'>"Music Festival 2025"</Box>, you can set the handle as
                    <Box fontWeight='700' display='inline'>"music-festival-2025"</Box>
                </Typography>
            </Menu>
        </>
    );
}