import axios from "axios";
import { formatApiError } from "../utils/helperUtils";
import { ACCESS_TOKEN_KEY, BASE_URL, REFRESH_TOKEN_KEY } from "../utils/constants";

/**
 * Retrieves the access token from localStorage
 */
const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

/**
 * Retrieves the refresh token from localStorage
 */
const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

/**
 * Refreshes the access token using the refresh token
 */
const refreshAccessToken = async (login, logout) => {
    try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) throw new Error("No refresh token available");

        const response = await axios.post(`${BASE_URL}/api/v1/auth/token/refresh/`, {
            refresh: refreshToken,
        });

        login(response.data.access, refreshToken);
        return response.data.access;
    } catch (error) {
        console.error("Error refreshing access token:", error);
        logout();
        throw new Error("Session expired. Please sign in again.");
    }
};

/**
 * Calls a protected API endpoint with automatic token refresh handling
 * @param {string} url - API endpoint
 * @param {string} method - HTTP method (GET, POST, PATCH, PUT, DELETE)
 * @param {object} [data=null] - Request body (for POST, PATCH, PUT)
 * @param {object} [headers={}] - Additional headers
 */
const callProtectedAPI = async (endpoint, method = "GET", data = null, headers = {}, login, logout) => {

    const url = `${BASE_URL}/api/v1${endpoint}`;
    try {
        let token = getAccessToken();
        if (!token) throw new Error("No access token. Please sign in.");

        // Set Authorization header
        const config = {
            method,
            url,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                ...headers,
            },
            ...(data && { data }), // Only add `data` for POST, PATCH, PUT
        };

        const response = await axios(config);
        return { success: true, data: response.data }

    } catch (error) {
        if (error.response?.status === 401) {
            // Token expired, attempt refresh
            try {
                const newToken = await refreshAccessToken(login, logout);
                const retryConfig = {
                    method,
                    url,
                    headers: {
                        Authorization: `Bearer ${newToken}`,
                        "Content-Type": "application/json",
                        ...headers,
                    },
                    ...(data && { data }),
                };

                const retryResponse = await axios(retryConfig);
                return { success: true, data: retryResponse.data };
            } catch (refreshError) {
                throw refreshError;
            }
        } else {
            throw new Error(formatApiError(error, "Error."));
        }
    }
};

/**
 * Registers a new user
 * @param {Object} userData - { email, password, confirmPassword, ... }
 */
const registerUser = async (userData) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/v1/auth/register/`, userData);
        return response;
    } catch (error) {
        throw new Error(formatApiError(error, "Registration failed."));
    }
};

/**
 * Logs in a user and stores the access & refresh tokens
 * @param {string} email
 * @param {string} password
 */
const signInUser = async (email, password, login) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/v1/auth/token/`, {
            email,
            password,
        });

        login(response.data.access, response.data.refresh);
        return response;
    } catch (error) {
        throw new Error(formatApiError(error, "Login failed."));
    }
};

/**
 * Google OAuth Sign-In
 * @param {string} token - Google OAuth token
 */
const googleSignInService = async (token, login) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/v1/auth/google/`,  token );
        login(response.data.access, response.data.refresh);
        return response;
    } catch (error) {
        throw new Error(formatApiError(error, "Google sign-in failed."));
    }
};

export {
    registerUser,
    signInUser,
    googleSignInService,
    refreshAccessToken,
    callProtectedAPI,
};