import { Box, Divider, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react';
import { theme } from '../../theme';
import { cardGridSize } from '../../utils/constants';
import ApproveVendorCardComponent from './components/ApproveVendorCardComponent';
import ManageEventsCardComponent from './components/ManageEventsCardComponent';
import SearchIcon from '@mui/icons-material/Search';
import { AppBarPadding, BottomPadding } from '../../utils/helperUtils';

function ApproveVendorsView() {
    const [searchQuery, setSearchQuery] = useState("");

    return (
        <Box className="events-box-container" bgcolor={theme.colors.events.primary}>
            <AppBarPadding />
            <Grid container justifyContent={'center'}>
                <Grid>
                    <Grid size={cardGridSize}>
                        <ManageEventsCardComponent />
                    </Grid>
                    <Typography fontSize={30} color={theme.colors.events.secondary}>
                        <Box sx={{ fontWeight: 'bold', mb: 1, mt: 1 }}>
                            Approve Vendors ✅
                        </Box>
                    </Typography>
                    <Divider sx={{ borderBottomWidth: 2, bgcolor: theme.colors.events.secondary }} />

                    {/* 🔍 Search Bar */}
                    <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'center' }}>
                        <TextField
                            variant="outlined"
                            size='small'
                            placeholder="Search vendors..."
                            fullWidth
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                sx: { borderRadius: 4, color: theme.colors.events.secondary },
                                endAdornment: (
                                    <InputAdornment position="end" sx={{ justifyContent: 'flex-end' }}>
                                        <IconButton
                                            size='small'
                                            onClick={() => console.log("Searching for:", searchQuery)}
                                            sx={{
                                                color: theme.colors.events.secondary,
                                            }}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                maxWidth: 400, // Limits width for better UI
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: theme.colors.events.secondary,
                                    },
                                    "&:hover fieldset": {
                                        borderColor: theme.colors.events.secondary,
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: theme.colors.events.secondary,
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Grid container spacing={2} mt={2}>
                        <Grid size={{ xs: 6, md: 6 }}>
                            <ApproveVendorCardComponent />
                        </Grid>
                        <Grid size={{ xs: 6, md: 6 }}>
                            <ApproveVendorCardComponent />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <BottomPadding />
        </Box>
    )
}

export default ApproveVendorsView