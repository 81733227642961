import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, ButtonGroup, Card, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import logo from '../../../assets/logo_no_text_primary.png';

function ApproveVendorCardComponent() {
    return (
        <Card sx={{ p: 1, borderRadius: 3 }} >
            <Grid container alignItems="center" textAlign={'center'} spacing={1}>

                {/* Left Section: Image */}
                <Grid size={{ xs: 12 }} display="flex" justifyContent="center">
                    <Box sx={{ width: '100%', maxWidth: '5rem' }}> {/* Dynamic size */}
                        <img src={logo} alt="Sidewalk Logo" style={{ width: '100%', height: 'auto' }} />
                    </Box>
                </Grid>

                {/* Right Section: Text Content */}
                <Grid size={{ xs: 12 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Parth's Popcorns
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <ButtonGroup variant="text" aria-label="Basic button group">
                        <Button>✅</Button>
                        <Button>❌</Button>
                        <Button><VisibilityIcon sx={{ color: 'steelblue' }} /></Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Card>
    )
}

export default ApproveVendorCardComponent