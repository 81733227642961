import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShareIcon from '@mui/icons-material/IosShare';
import MotionPhotosOffIcon from "@mui/icons-material/MotionPhotosOff";
import TikTokIcon from '@mui/icons-material/MusicNote'; // No direct TikTok icon, use MusicNote or custom SVG
import XIcon from '@mui/icons-material/X';
import { Avatar, Box, Button, Divider, IconButton, ImageList, ImageListItem, Modal, Rating, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "../../App.css";
import logo from "../../assets/logo_no_text.png";
import { deleteMyStorefrontReview, fetchMyStorefrontReviews, fetchPhotosService, fetchStorefrontReviews, fetchStorefrontReviewsAuth, fetchVendorDataService, postStorefrontReview } from '../../services/appServices';
import "../../styles/vendor.css";
import { theme } from '../../theme';
import { AppBarPadding, BottomPadding } from '../../utils/helperUtils';
import LoadingComponent from '../components/LoadingComponent';
import { MapView } from './components/MapComponent';
import { cardGridSize } from '../../utils/constants';
import ReviewComponent from './components/ReviewComponent';
import { useAuth } from '../../context/AuthContext';
import { useSnackbar } from '../../context/SnackbarContext';
import CustomInput from '../components/CustomComponents';

function VendorViewV2() {

    const { slug } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, login, logout } = useAuth();
    const showSnackbar = useSnackbar();

    const [storeData, setStoreData] = useState(null);
    const [storeMenuPhotos, setStoreMenuPhotos] = useState([]);
    const [storeFrontPhotos, setStoreFrontPhotos] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openSelectedImage, setOpenSelectedImage] = useState(false);
    const [selectedTab, setSelectedTab] = useState("items");
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [storeLocationOpen, setStoreLocationOpen] = useState(false);
    const [isViewingAvatar, setIsViewingAvatar] = useState(false);
    const [storeReviews, setStoreReviews] = useState([]);
    const [myReviews, setMyReviews] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editedReviewText, setEditedReviewText] = useState("");
    const [editedRating, setEditedRating] = useState(0);
    const [refreshKey, setRefreshKey] = useState(0);

    const avatarSize = {
        xs: {
            size: storeData?.logo ? 120 : 70,
            bglen: storeData?.logo ? '160px' : '160px',
        },
        md: {
            size: storeData?.logo ? 120 : 90,
            bglen: storeData?.logo ? '160px' : '160px',
        },
    };

    useEffect(() => {
        let isMounted = true; // Prevents memory leaks on unmount

        const fetchAllData = async () => {
            try {
                setLoading(true);

                // Fetch all data in parallel, allowing individual failures
                const [
                    vendorResponse,
                    menuPhotosResponse,
                    storefrontPhotosResponse,
                    reviewsResponse,
                    myReviewsResponse
                ] = await Promise.allSettled([
                    fetchVendorDataService(slug),
                    fetchPhotosService(`${slug}/menu_photos/`),
                    fetchPhotosService(`${slug}/storefront_photos/`),
                    isAuthenticated ? fetchStorefrontReviewsAuth(slug, login, logout) : fetchStorefrontReviews(slug),
                    isAuthenticated ? fetchMyStorefrontReviews(slug, login, logout) : null
                ]);

                if (!isMounted) return; // Prevent state updates if unmounted

                // Helper function to check success
                const getData = (response) => (response.status === "fulfilled" ? response.value : null);
                const getError = (response) => (response.status === "rejected" ? response.reason.message : null);

                // Store Vendor Data
                if (getData(vendorResponse)?.success) {
                    setStoreData(getData(vendorResponse).data);
                } else {
                    setError(getError(vendorResponse) || "Failed to fetch vendor data");
                }

                // Store Photos Data
                if (getData(menuPhotosResponse)?.success) setStoreMenuPhotos(getData(menuPhotosResponse).data);
                if (getData(storefrontPhotosResponse)?.success) setStoreFrontPhotos(getData(storefrontPhotosResponse).data);

                // Store Reviews Data
                if (getData(reviewsResponse)?.success) {
                    setStoreReviews(getData(reviewsResponse).data);
                }

                if (getData(myReviewsResponse)?.success) {
                    setMyReviews(getData(myReviewsResponse).data);
                }
                else {
                    setMyReviews(null)
                }

            } catch (error) {
                console.error("Unexpected error fetching data:", error);
                showSnackbar(error.message || "An unknown error occurred", "error");
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        fetchAllData();

        return () => {
            isMounted = false; // Cleanup function
        };
    }, [slug, isAuthenticated, refreshKey]);

    // Handle opening Lightbox
    const handleSelectedImageOpen = (index) => {
        setSelectedImageIndex(index);
        setIsViewingAvatar(false); // Reset when not viewing Avatar
        setOpenSelectedImage(true);
    };

    // Handle opening Lightbox for Avatar
    const handleAvatarPreview = () => {
        setIsViewingAvatar(true);
        setOpenSelectedImage(true);
    };

    // Handle closing Lightbox
    const handleSelectedImageClose = () => {
        setOpenSelectedImage(false);
        setIsViewingAvatar(false); // Reset when closing
    };

    const handleChange = (_, newTab) => {
        if (newTab !== null) {
            setSelectedTab(newTab);
        }
    };

    const handleEditReview = (review = null) => {
        setEditedReviewText(review?.review_text || "");
        setEditedRating(review?.rating || 0);
        setEditModalOpen(true);
    };

    const handleSubmitReview = async () => {
        try {
            const response = await postStorefrontReview(slug, editedRating, editedReviewText, login, logout);

            if (response.success) {
                showSnackbar("🎉 Review submitted successfully!", "success");
                setEditModalOpen(false);
                setRefreshKey(prev => prev + 1);

            } else {
                showSnackbar("⚠️ Failed to submit review.", "error");
            }
        } catch (error) {
            console.error("Error submitting review:", error);
            showSnackbar(error.message || "Something went wrong.", "error");
        }
    };

    const handleDeleteReview = async () => {
        try {
            const response = await deleteMyStorefrontReview(slug, login, logout);

            if (response.success) {
                showSnackbar("✅ Review deleted successfully!", "success");
                setRefreshKey(prev => prev + 1);  // 🔹 Refresh the reviews list
            } else {
                showSnackbar("⚠️ Failed to delete review.", "error");
            }
        } catch (error) {
            console.error("Error deleting review:", error);
            showSnackbar(error.message || "Something went wrong.", "error");
        }
    };

    const SocialMediaIcons = () => {
        const fontSize = 30;

        // Ensure urls object only contains valid values (removes undefined/null values)
        const urls = {
            facebook_url: storeData?.facebook_url,
            tiktok_url: storeData?.tiktok_url,
            instagram_url: storeData?.instagram_url,
            x_url: storeData?.x_url
        };

        // Check if there are any valid URLs
        const hasSocialLinks = Object.values(urls).some(url => url);

        const icons = [
            { icon: <InstagramIcon sx={{ color: "white", fontSize }} />, bgColor: "#E1306C", hoverColor: "#a72d6f", key: "instagram_url" },
            { icon: <TikTokIcon sx={{ color: "white", fontSize }} />, bgColor: "#000", hoverColor: "#333", key: "tiktok_url" },
            { icon: <XIcon sx={{ color: "white", fontSize }} />, bgColor: "#000", hoverColor: "#333", key: "x_url" },
            { icon: <FacebookIcon sx={{ color: "white", fontSize }} />, bgColor: "#1877F2", hoverColor: "#145DBF", key: "facebook_url" }
        ];

        const handleShare = () => {
            if (navigator.share) {
                navigator.share({
                    title: "Check out this store!",
                    text: "Discover this amazing store on Sidewalk!",
                    url: window.location.href, // Dynamically share the current page URL
                })
                    .then(() => console.log("Shared successfully!"))
                    .catch((error) => console.error("Error sharing:", error));
            } else {
                alert("Sharing is not supported on this device.");
            }
        };

        const handleSocialClick = (url) => {

            window.open(url, "_blank");
        };

        // If no valid social media links, return only the Share button
        if (!hasSocialLinks) {
            return (
                <Button
                    variant="contained"
                    startIcon={<ShareIcon />}
                    sx={{
                        backgroundColor: theme.colors.secondary,
                        color: 'white',
                        borderRadius: 4, mt: 1
                    }}
                    onClick={handleShare}
                >
                    Share this store
                </Button>
            );
        }

        return (
            <Box display="flex" justifyContent="center" gap={4} mt={2}>
                {icons
                    .filter(item => urls[item.key]) // Only include icons that have a valid URL
                    .map((item, index) => (
                        <IconButton
                            key={item.key}
                            className="social-icon-button"
                            sx={{
                                backgroundColor: item.bgColor,
                                "&:hover": { backgroundColor: item.hoverColor }
                            }}
                            onClick={() => handleSocialClick(urls[item.key])}
                        >
                            {item.icon}
                        </IconButton>
                    ))}

                {/* Always show the Share icon */}
                <IconButton
                    className="social-icon-button"
                    sx={{
                        backgroundColor: theme.colors.secondary,
                        "&:hover": { backgroundColor: theme.colors.secondaryDark }
                    }}
                >
                    <ShareIcon sx={{ color: "white", fontSize }} onClick={handleShare} />
                </IconButton>
            </Box>
        );
    };

    if (loading) {
        return (
            <LoadingComponent />
        );
    }

    if (error) {
        return (
            <Box textAlign="center" mt={4} className="box-container">
                <AppBarPadding />
                <Typography color="error" variant="h6">⚠️ {error || 'N/A'}</Typography>
                <BottomPadding />
            </Box>
        );
    }

    return (
        <>
            <Box
                className="box-container"
                sx={{
                    background: `linear-gradient(to bottom, ${theme.colors.primary} 0%, ${theme.colors.primary} ${avatarSize.xs.bglen}, transparent ${avatarSize.xs.bglen})`,
                    "@media (min-width:600px)": {
                        background: `linear-gradient(to bottom, ${theme.colors.primary} 0%, ${theme.colors.primary} ${avatarSize.md.bglen}, transparent ${avatarSize.md.bglen})`,
                    },
                }}
            >
                <AppBarPadding />

                <Grid container justifyContent="center" textAlign="center">
                    <Grid size={{ md: 12, lg: 6 }} container flexDirection="column" alignItems="center">

                        {/* Avatar */}
                        <Avatar alt="Logo"
                            src={storeData?.logo || logo}
                            onClick={handleAvatarPreview}
                            sx={{
                                width: { xs: avatarSize.xs.size, md: avatarSize.md.size },
                                height: { xs: avatarSize.xs.size, md: avatarSize.md.size },
                                border: "2px solid white",
                                backgroundColor: "white",
                                padding: storeData?.logo ? 0 : 2,
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                            }}
                        />

                        {/* Vendor Info */}
                        <Typography fontSize={30} fontWeight={700} color={theme.colors.black} mt={1}>
                            {storeData?.name || 'N/A'}
                        </Typography>

                        <Typography fontSize="small" color="gray" mt={1}>
                            {storeData?.cuisine || 'N/A'}
                        </Typography>
                        <Typography fontSize={20} mt={1}>
                            {storeData?.description ?? ""}
                        </Typography>
                        <SocialMediaIcons />
                        {/* Store Status Indicator */}
                        <Grid
                            container
                            alignItems="center"
                            mt={2}
                            gap={1}
                        >
                            {/* Status Indicator (Pulsing Circle or Icon) */}
                            <Grid>
                                {storeLocationOpen ? (
                                    <Box className="pulse-container">
                                        <Box className="pulse-circle"></Box>
                                        <Box className="solid-circle"></Box>
                                    </Box>
                                ) : (
                                    <MotionPhotosOffIcon sx={{ color: theme.colors.red }} />
                                )}
                            </Grid>

                            {/* Store Status Text */}
                            <Grid size={{ xs: 'grow' }}>
                                <Typography variant="body1" fontWeight={500} ml={1}>
                                    {storeData?.name || 'N/A'} is currently {storeLocationOpen ? 'open' : 'closed'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <MapView slug={slug} setStoreLocationOpen={setStoreLocationOpen} />

                        <Box display="flex" justifyContent="center" mt={2}>
                            <ToggleButtonGroup
                                value={selectedTab}
                                exclusive
                                onChange={handleChange}
                                sx={{
                                    "& .MuiToggleButtonGroup-grouped": {
                                        borderRadius: "15px", // Rounded corners
                                        border: "none",
                                        fontWeight: "bold",
                                        padding: "6px 16px",
                                    },
                                    "& .MuiToggleButton-root": {
                                        color: theme.colors.black,
                                        mx: 2,
                                        "&:hover": {
                                            backgroundColor: theme.colors.primary, // Change hover color for selected
                                            color: "white",
                                        },
                                        "&.Mui-selected": {
                                            backgroundColor: theme.colors.secondary,
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: theme.colors.secondary, // Change hover color for selected
                                                color: "white",
                                            },
                                        },
                                    },
                                }}
                            >
                                <ToggleButton value="photos">Photos</ToggleButton>
                                <ToggleButton value="items">Items</ToggleButton>
                                <ToggleButton value="reviews">Reviews</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box mt={2}>
                            {selectedTab === "reviews" ? (
                                <Grid container spacing={2} maxWidth={600}>
                                    <Grid size={{ xs: 12 }}>
                                        {myReviews ?
                                            <ReviewComponent
                                                reviewer_name={myReviews.reviewer_name}
                                                review_date={myReviews.review_date}
                                                review_text={myReviews.review_text || ""}
                                                rating={myReviews.rating}
                                                my_review={true}
                                                onDelete={() => handleDeleteReview()}
                                            />
                                            :
                                            <Button
                                                color='primary'
                                                variant='contained'
                                                fullWidth
                                                size='large'
                                                sx={{ borderRadius: 5 }}
                                                onClick={() => {
                                                    if (!isAuthenticated) {
                                                        navigate("/_/login", { state: { from: location.pathname } });
                                                    } else {
                                                        handleEditReview()
                                                    }
                                                }}                                            >
                                                {isAuthenticated ? "Spill your thoughts! ☕💭" : "Log in to drop a review! 📝✨"}
                                            </Button>
                                        }
                                    </Grid>
                                    <Grid size={{ xs: 12 }}>  <Divider />  </Grid>
                                    <Grid size={{ xs: 12 }}>
                                        {storeReviews?.length == 0 && !myReviews ? (<Typography variant="h6" textAlign="center">💬 No reviews yet, Be the first one! 🥳</Typography>
                                        ) : (<>


                                            {storeReviews.map((review, index) => (
                                                <Grid size={cardGridSize} key={index}>
                                                    {<ReviewComponent
                                                        key={index}
                                                        reviewer_name={review.reviewer_name}
                                                        review_date={review.review_date}
                                                        review_text={review.review_text || ""}
                                                        rating={review.rating}
                                                    />}
                                                </Grid>
                                            ))}

                                        </>)}
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    {selectedTab === "items" && storeMenuPhotos.length == 0 && (
                                        <Typography variant="h6" textAlign="center">🚫 No snapshots here! Just imagine everything looking chef’s kiss 👨‍🍳✨</Typography>
                                    )}
                                    {selectedTab === "photos" && storeFrontPhotos.length == 0 && (
                                        <Typography variant="h6" textAlign="center">🚀 No storefront photos, but hey, surprises are fun, right? 🎁</Typography>
                                    )}
                                    {((selectedTab === "items" && storeMenuPhotos.length > 0) ||
                                        (selectedTab === "photos" && storeFrontPhotos.length > 0)) && (
                                            <ImageList
                                                cols={3} // Ensures 3 columns
                                                gap={8} // Optional: Adjust spacing between images
                                                sx={{
                                                    width: "100%", // Full width
                                                    maxWidth: 600, // Optional: Set a max width
                                                    margin: "auto", // Centering
                                                }}
                                            >
                                                {(selectedTab === "items" ? storeMenuPhotos : storeFrontPhotos).map((item, index) => {
                                                    const imageUrl = selectedTab === "items" ? item.menu_photo : item.storefront_photo; // Correct key
                                                    const alt = selectedTab === "items" ? "Item Photo" : "Store Photo"
                                                    return (
                                                        <ImageListItem key={imageUrl} sx={{ position: "relative" }}>
                                                            <Box
                                                                component="img"
                                                                srcSet={imageUrl}
                                                                src={imageUrl}
                                                                alt={alt}
                                                                loading="lazy"
                                                                sx={{
                                                                    width: "100%",
                                                                    aspectRatio: "1 / 1",
                                                                    objectFit: "cover",
                                                                    borderRadius: 2,
                                                                }}
                                                                onClick={() => handleSelectedImageOpen(index)} // Pass index
                                                            />
                                                        </ImageListItem>
                                                    );
                                                })}
                                            </ImageList>
                                        )}
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <BottomPadding />
            </Box>

            {/* Lightbox Preview */}
            <Lightbox
                open={openSelectedImage}
                close={handleSelectedImageClose}
                carousel={{
                    finite: isViewingAvatar || (selectedTab === "items" ? storeMenuPhotos.length === 1 : storeFrontPhotos.length === 1),
                }}
                slides={isViewingAvatar
                    ? [{ src: storeData?.logo || logo }]  // Single image for Avatar
                    : (selectedTab === "items" ? storeMenuPhotos : storeFrontPhotos).map((item) => ({
                        src: selectedTab === "items" ? item.menu_photo : item.storefront_photo,
                    }))
                }
                index={isViewingAvatar ? 0 : selectedImageIndex} // Disable slides when viewing Avatar
            />
            {/* Edit Review Modal */}
            <Modal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                aria-labelledby="edit-review-modal"
                aria-describedby="edit-review-form"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 3,
                        borderRadius: 3,
                        width: { xs: "80%", md: "50%" }
                    }}
                >
                    <Typography variant="h6" fontWeight={600} mb={2}>Edit Your Review</Typography>
                    <Rating
                        value={editedRating}
                        precision={0.5}
                        onChange={(event, newValue) => setEditedRating(newValue)}
                        size="large"
                        sx={{ mb: 2 }}
                    />
                    <CustomInput
                        label="What do you think?"
                        type="text"
                        placeholder="Tell us about your experience..or don't, this part is optional"
                        value={editedReviewText}
                        onChange={setEditedReviewText}
                        multiline
                        minRows={2}

                    />
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Button sx={{ borderRadius: 4 }} onClick={() => setEditModalOpen(false)} variant="outlined" color="secondary">Cancel</Button>
                        <Button sx={{ borderRadius: 4 }} onClick={handleSubmitReview} variant="contained" color="primary">Submit</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default VendorViewV2;