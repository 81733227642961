import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Link, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from "react";

import "../../App.css"; // Assuming your CSS file is named App.css

function Footer() {

  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    // Check if the footer has been hidden previously
    const hidden = localStorage.getItem("footerHidden");
    if (hidden === "true") {
      setIsHidden(true);
    }
  }, []);

  const handleClose = () => {
    setIsHidden(true);
    localStorage.setItem("footerHidden", "true"); // Store in localStorage
  };

  if (isHidden) return null; // Hide the footer

  return (
    <Grid
      className="footer"
      container
    >
      <Grid size={{ xs: 11 }} container alignItems={'center'} justifyContent={'center'}>
        <Typography variant="caption" color="textSecondary">
          By viewing or using this page, you agree to our{" "}
          <Link
            href="_/terms-of-service"
            underline="hover"
          >
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link
            href="_/privacy-policy"
            underline="hover"
          >
            Privacy Policy
          </Link>

        </Typography>
      </Grid>
      <Grid size={{ xs: 1 }} container alignItems={'center'}>
        {/* Close button */}
        <IconButton
          onClick={handleClose}        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default Footer;
