import CancelIcon from "@mui/icons-material/Cancel";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, ButtonBase, Divider, Fade, IconButton, List, ListItem, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from "react";
import "../../App.css";
import logo from '../../assets/logo_no_text.png';
import logoText from '../../assets/logo_text_black.png';
import { useAppContext } from "../../context/AppContext";
import "../../styles/appBar.css";
import { theme } from "../../styles/theme";
import { useSnackbar } from "../../context/SnackbarContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const AppBarComponent = () => {

    const { menuOpen, toggleMenu } = useAppContext();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();
    const { isAuthenticated, logout } = useAuth(); 

    // Handle Logout
    const handleLogout = () => {
        logout();
        showSnackbar("😭 See you soon", "success");
        navigate("/");
        toggleMenu(); // Close menu after logging out
    };

    const handleContactUs = () => {
        const newTab = window.open("", "_blank"); // Open a new blank tab
        newTab.location.href = "mailto:hello@sidewa.lk"; // Set mailto link
    };

    // Define menu items dynamically based on authentication state
    const menuItems = [
        !isAuthenticated ? { text: "Sign In", path: "/_/login" } : null,
        { text: "About Us", path: "/_/about" },
        { text: "Contact Us", action: handleContactUs },
        { text: "FAQs", path: "/_/support" },
        isAuthenticated ? { text: "Log Out", action: handleLogout } : null
    ].filter(Boolean);

    return (
        <div className={`app-container`}
        >
            <Grid container justifyContent={'center'}>
                <Grid container className="app-bar" size={{ xs: 11, md: 10, lg: 6 }}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <ButtonBase sx={{ borderRadius: 5 }} onClick={() => { toggleMenu(); navigate("/") }}>
                        <img src={logo} alt="Sidewalk Logo" className="logo" />
                        <img src={logoText} alt="Sidewalk Logo" className="logoText" />
                    </ButtonBase>
                    <IconButton onClick={toggleMenu}>
                        {menuOpen ? <CancelIcon sx={{ fontSize: 30, color: theme.colors.primary }} /> :
                            <MenuIcon sx={{ fontSize: 30, color: "black" }} />}
                    </IconButton>
                </Grid>
            </Grid>
            <Fade in={menuOpen}>
                <Box
                    sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        background: theme.colors.primaryLight,
                        zIndex: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        transition: "all 0.3s ease-in-out",
                    }}
                >

                    <List sx={{ textAlign: "center", padding: 2, pb: 10 }}>
                        {menuItems.map(({ text, path, action }) => (
                            <React.Fragment key={text}>
                                <ListItem
                                    onClick={() => {
                                        if (action) {
                                            action(); // Handle Log Out
                                        } else {
                                            navigate(path);
                                            toggleMenu();
                                        }
                                    }}
                                    sx={{
                                        cursor: "pointer",
                                        "&:hover": { color: theme.colors.primaryDark }
                                    }}
                                >
                                    <Typography variant="h4" fontWeight="800" color="white">
                                        {text}
                                    </Typography>
                                </ListItem>
                                <Divider className="divider" sx={{ my: 1 }} />
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            </Fade>





        </div>
    );
};

export default AppBarComponent;