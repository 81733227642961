import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../../styles/theme';

function SearchEventsVendorCardComponent() {
    return (
        <Accordion
            square={true}
            sx={{
                borderRadius: 5,
                overflow: "hidden",
                "&:before": { display: "none" } // Removes the default divider line
            }}
        >
            {/* Accordion Header */}
            <AccordionSummary
                sx={{
                }}
                expandIcon={<ExpandMoreIcon className='manage-card-icon-circle' sx={{ color: theme.colors.events.primary }} />}>
                <Box>
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography variant='h6' color={theme.colors.events.black} sx={{ display: "inline" }}>
                            Monday Market
                        </Typography>
                    </Box>
                    <Typography variant='body2' color={'textSecondary'}>
                        November 13, 2025 at 9:00 PM
                    </Typography>
                </Box>
            </AccordionSummary>
            {/* Accordion Content */}
            <AccordionDetails sx={{ py: 0, mt: -2 }}>
                <List>
                    <ListItemButton>
                        <ListItemText primary="📍" sx={{ maxWidth: "35px" }} />
                        <ListItemText primary="888 8th Ave, New York" />
                        <OpenInNewIcon fontSize="small" sx={{ color: 'goldenrod' }} />

                        {/* </Box> */}
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText primary="✅" sx={{ maxWidth: "35px" }} />
                        <ListItemText primary="Apply to Event" />
                    </ListItemButton>

                    <ListItemButton>
                        <ListItemText primary="👀" sx={{ maxWidth: "35px" }} />
                        <ListItemText primary="View Event" />
                    </ListItemButton>

                    <ListItemButton>
                        <ListItemText primary="🌟" sx={{ maxWidth: "35px" }} />
                        <ListItemText primary="Favorite Event" />
                    </ListItemButton>
                </List>
            </AccordionDetails>
        </Accordion>
    );
}

export default SearchEventsVendorCardComponent;