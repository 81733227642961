import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import LoginView from "./views/Auth/Login/LoginView";
import PasswordResetView from "./views/Auth/PasswordReset/PasswordResetView";
import SignUpView from "./views/Auth/SignUp/SignUpView";
import AppBarComponent from "./views/components/AppBarComponent";
import Footer from "./views/components/FooterComponent";
import ApproveVendorsView from "./views/Events/ApproveVendorsView";
import CreateEventsView from "./views/Events/CreateEventsView";
import EventsView from "./views/Events/EventsView";
import ManageEventsView from "./views/Events/ManageEventsView";
import SearchEventsVendorView from "./views/Events/SearchEventsVendorView";
import HomeView from "./views/Home/HomeView";
import PrivacyPolicyView from "./views/PrivacyPolicy/PrivacyPolicyView";
import SupportView from "./views/Support/SupportView";
import TermsOfServiceView from "./views/TermsOfService/TermsOfServiceView";
import VendorViewV2 from "./views/Vendor/VendorViewV2";
import { SnackbarProvider } from "./context/SnackbarContext";
import AboutUs from "./views/Support/AboutUs";

function App() {

  const subdomain = window.location.hostname.split(".")[0] || "";

  return (
    <>
      <SnackbarProvider>
        <Router>
          <AppBarComponent />
          <Routes>
            {subdomain === "events" ? (
              <>
                <Route path="/" element={<EventsView />} />
                <Route path="/manage" element={<ManageEventsView />} />
                <Route path="/create" element={<CreateEventsView />} />
                <Route path="/approve" element={<ApproveVendorsView />} />
                <Route path="/search-events-vendor" element={<SearchEventsVendorView />} />
              </>
            ) : (
              <>
                <Route path="/" element={<HomeView />} />
                <Route path="/:slug" element={<VendorViewV2 />} />
                <Route path="/_/login" element={<LoginView />} />
                <Route path="/_/signup" element={<SignUpView />} />
                <Route path="/_/password-reset" element={<PasswordResetView />} />
                <Route path="/_/privacy-policy" element={<PrivacyPolicyView />} />
                <Route path="/_/terms-of-service" element={<TermsOfServiceView />} />
                <Route path="/_/support" element={<SupportView />} />
                <Route path="/_/about" element={<AboutUs />} />
              </>
            )}
          </Routes>
        </Router>
        <Footer />
      </SnackbarProvider>
    </>
  );
}

export default App;
