import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import "../../App.css";
import eventIcon from "../../assets/event_home_icons.png";
import "../../styles/events.css"; // Import CSS
import { theme } from '../../styles/theme';


function EventsView() {

  return (
    <Box>
      <div className="events-container"
        style={{
          background: theme.colors.events.primary,
          paddingTop: "60px"
        }}
      >
        <Grid container justifyContent={'center'} px={5} >
          <Grid size={{ xs: 12, md: 10, }} >
            <Grid container justifyContent={'center'}>
              <Grid size={{ xs: 12, lg: 6 }}>
                <h1 className="events-title">
                  Sidewalk is where the best events meet the best vendors! ✨
                </h1>
              </Grid>
            </Grid>

            <Grid container justifyContent={'center'}>
              <Grid size={{ xs: 12, lg: 6 }}>
                <h4 className="events-description">
                  Find incredible local events and top-rated vendors all in one place.
                  From pop-ups to food trucks, discover what’s happening around you in real time.
                  Support small businesses, explore unique experiences,
                  and never miss out on what’s trending.
                </h4>
              </Grid>
            </Grid>

            <Grid container justifyContent={'center'}>
              <Grid size={{ xs: 12, lg: 6 }}>
                <Button variant="contained" className="host-button"
                  sx={{
                    background: theme.colors.events.secondary,
                    color: theme.colors.events.black
                  }}>
                  Host your event
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent={'center'}>
              <Grid size={{ xs: 12, lg: 6 }} pt={5} container direction={'row-reverse'}>
                <img src={eventIcon} alt="Event Icon" className="event-icon" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="events-container"
        style={{
          background: theme.colors.primary,
          paddingTop: "80px"
        }}
      >
        <Grid container justifyContent={'center'} px={5} >
          <Grid size={{ xs: 12, md: 10, }} >
            <Grid container justifyContent={'center'}>
              <Grid size={{ xs: 12, lg: 6 }}>
                <h1 className="events-title">
                  Sidewalk is where the best events meet the best vendors! ✨
                </h1>
              </Grid>
            </Grid>

            <Grid container justifyContent={'center'}>
              <Grid size={{ xs: 12, lg: 6 }}>
                <h4 className="events-description">
                  Find incredible local events and top-rated vendors all in one place.
                  From pop-ups to food trucks, discover what’s happening around you in real time.
                  Support small businesses, explore unique experiences,
                  and never miss out on what’s trending.
                </h4>
              </Grid>
            </Grid>

            <Grid container justifyContent={'center'}>
              <Grid size={{ xs: 12, lg: 6 }}>
                <Button variant="contained" className="host-button"
                  sx={{
                    background: theme.colors.events.secondary,
                    color: theme.colors.events.black
                  }}>
                  Host your event
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent={'center'}>
              <Grid size={{ xs: 12, lg: 6 }} pt={5} container direction={'row-reverse'}>
                <img src={eventIcon} alt="Event Icon" className="event-icon" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default EventsView;