import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

function LoadingComponent() {
    return (
        <>

            <Box display="flex" justifyContent="center" alignItems="center" height="100vh" flexDirection={'column'} gap={4}>
                <CircularProgress color='primary' />
                <Typography variant='h5'>Worth the wait 💈</Typography>
            </Box>
        </>
    )
}

export default LoadingComponent