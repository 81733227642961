import React, { useState } from "react";
import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const CustomInput = ({ label, placeholder, type = "text", onChange, value: propValue, ...props }) => {
    const [value, setValue] = useState(propValue || "");
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        if (onChange) onChange(newValue); // Calls parent onChange if provided
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <Box>
            {label && (
                <Typography variant="body1" ml={1} mb={0.5}>
                    {label}
                </Typography>
            )}
            <TextField
                variant="outlined"
                fullWidth
                type={type === "password" ? (showPassword ? "text" : "password") : type}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                InputProps={{
                    sx: {
                        borderRadius: 5,
                    },
                    ...( type === "password" && {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }),
                }}
                {...props}
            />
        </Box>
    );
};

export default CustomInput;