import SearchIcon from '@mui/icons-material/Search';
import { Box, Divider, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react';
import "../../styles/events.css"; // Import CSS
import { theme } from '../../styles/theme';
import { cardGridSize } from '../../utils/constants';
import { AppBarPadding, BottomPadding } from '../../utils/helperUtils';
import SearchEventsVendorCardComponent from './components/SearchEventsVendorCardComponent';

function SearchEventsVendorView() {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <Box className="events-box-container" bgcolor={theme.colors.events.primary}>
      <AppBarPadding />
      <Grid container justifyContent={'center'}>
        <Grid size={{ md: 12, lg: 6 }}>
          <Typography fontSize={{ xs: 25, md: 30 }} color={theme.colors.events.secondary}>
            <Box sx={{ fontWeight: 'bold', mb: 1 }}>
              Search Nearby Events 🔍
            </Box>
          </Typography>
          <Divider sx={{ borderBottomWidth: 2, bgcolor: theme.colors.events.secondary }} />
          {/* 🔍 Search Bar */}
          {/* TODO: make reuseable component */}
          <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'center' }}>
            <TextField
              variant="outlined"
              size='small'
              placeholder="Search events..."
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                sx: { borderRadius: 4, color: theme.colors.events.secondary },
                endAdornment: (
                  <InputAdornment position="end" sx={{ justifyContent: 'flex-end' }}>
                    <IconButton
                      size='small'
                      onClick={() => console.log("Searching for:", searchQuery)}
                      sx={{
                        color: theme.colors.events.secondary,
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                maxWidth: 400, // Limits width for better UI
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme.colors.events.secondary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.colors.events.secondary,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme.colors.events.secondary,
                  },
                },
              }}
            />
          </Box>
          <Grid container
            spacing={2}
            mt={2}
          >

            <Grid size={cardGridSize}>
              <SearchEventsVendorCardComponent />
            </Grid>
            <Grid size={cardGridSize}>
              <SearchEventsVendorCardComponent />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <BottomPadding />
    </Box>
  )
}

export default SearchEventsVendorView