import { callProtectedAPI } from "./authServices";

const BASE_URL = process.env.REACT_APP_BACKEND_URL; // Store backend URL as a constant

/**
 * Generic Fetch Service for API Requests
 * @param {string} endpoint - API endpoint (relative to BASE_URL)
 * @param {string} errorMessage - Custom error message for failures
 */
const fetchData = async (endpoint, errorMessage) => {
    try {
        const response = await fetch(`${BASE_URL}/api/v1/storefronts/${endpoint}`);

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`${errorMessage} (Error ${response.statusText || ""}: ${errorText || response.statusText})`);
        }

        const data = await response.json();
        return { success: true, data };
    } catch (error) {
        console.error(`Error fetching ${endpoint}:`, error);
        return { success: false, error: error.message };
    }
};

/**
 * Fetch Vendor Data
 * @param {string} slug - Storefront identifier
 */
export const fetchVendorDataService = async (slug) => {
    return fetchData(`${slug}/`, "Storefront does not exist");
};

/**
 * Fetch Storefront Photos
 * @param {string} endpoint - API endpoint (e.g., `menu_photos/`, `storefront_photos/`)
 */
export const fetchPhotosService = async (endpoint) => {
    return fetchData(endpoint, "Unable to fetch photos");
};

/**
 * Fetch Storefront Reviews
 * @param {string} slug - Storefront identifier
 */
export const fetchStorefrontReviews = async (slug) => {
    return fetchData(`${slug}/reviews/`, "Unable to fetch storefront reviews");
};

export const fetchStorefrontReviewsAuth = async (slug, login, logout) => {
    return callProtectedAPI(
        `/storefronts/${slug}/reviews/`,
        "GET",
        null,
        {},
        login,
        logout
    );
};

export const fetchMyStorefrontReviews = async (slug, login, logout) => {
    return callProtectedAPI(
        `/storefronts/${slug}/my_reviews/`,
        "GET",
        null,
        {},
        login,
        logout
    );
};

export const postStorefrontReview = async (slug, rating, review_text, login, logout) => {
    const formData = new FormData();

    formData.append("rating", rating);
    formData.append("review_text", review_text);

    return callProtectedAPI(
        `/storefronts/${slug}/reviews/`,
        "POST",
        formData,
        { "Content-Type": "multipart/form-data" }, // Set correct headers
        login,
        logout
    );
};

export const deleteMyStorefrontReview = async (slug, login, logout) => {
    return callProtectedAPI(
        `/storefronts/${slug}/my_reviews/`,
        "DELETE",
        null,  // No body needed for DELETE
        {},
        login,
        logout
    );
};