import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

const libraries = ["places"];

export const MapView = ({ slug, setStoreLocationOpen }) => {
  const [location, setLocation] = useState(null); // Starts as null for handling errors
  const [locationTime, setLocationTime] = useState(null);
  const [fetchError, setFetchError] = useState(false);

  const fetchVendorLocation = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/storefronts/${slug}/location/`
      );

      if (!response.ok) {
        setFetchError(true);
        return;
      }

      const response_json = await response.json();

      if (!response_json.location_coordinate) {
        setFetchError(true);
        return;
      }

      const vendor_location = {
        lat: response_json.location_coordinate.coordinates[1],
        lng: response_json.location_coordinate.coordinates[0],
      };

      setLocation(vendor_location);
      setLocationTime(response_json.location_time);
      setStoreLocationOpen(response_json.is_location_open);
      setFetchError(false); // Reset error state if successful
    } catch (error) {
      console.error("Error fetching vendor location:", error);
      setFetchError(true);
    }
  };

  useEffect(() => {
    fetchVendorLocation(); // Initial fetch

    const interval = setInterval(fetchVendorLocation, 15 * 60 * 1000); // 15 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  }, [slug]);

  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "15px",
  };

  const handleMarkerClick = () => {
    if (location) {
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`;
      window.open(googleMapsUrl, "_blank");
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });

  if (loadError) {
    return (
      <Box textAlign="center" p={2}>
        <Typography color="error">⚠️ Failed to load Google Maps.</Typography>
      </Box>
    );
  }

  if (!isLoaded) {
    return (
      <Box display="flex" mt={2}>
        <CircularProgress size={30} />
      </Box>
    );
  }

  if (fetchError || !location) {
    return (
      <Box textAlign="center" p={2}>
        <Typography color="error">⚠️ Unable to fetch store location.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{
      width: "100%",
      maxWidth: 600,
      height: { xs: 200, md: 250 },
      mt: 2,
      overflow: "hidden",
      display: 'flex'
    }}>
      <GoogleMap mapContainerStyle={containerStyle} center={location} zoom={15}>
        <MarkerF position={location} onClick={handleMarkerClick} />
      </GoogleMap>
    </Box>
  );
};