import ClearIcon from '@mui/icons-material/Clear';
import {
    Box, Card, CardContent, Typography, Avatar, Rating, CardHeader,
    IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../theme";

const ReviewComponent = ({ reviewer_name, review_date, review_text, rating, my_review = false, onDelete }) => {

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
            // year: "2-digit"
        });
    };

    return (
        <>
            <Card sx={{
                borderRadius: 3,
                boxShadow: 2,
                border: "0.5px solid #e0e0e0",
            }}>
                <CardHeader
                    sx={{ pb: 0 }}
                    avatar={
                        <Avatar sx={{ bgcolor: "primary.main", }}>
                            {reviewer_name?.charAt(0).toUpperCase() || '?'}
                        </Avatar>
                    }
                    action={
                        my_review ? (
                            <IconButton aria-label="delete" onClick={() => setDeleteDialogOpen(true)}>
                                <ClearIcon sx={{ color: theme.colors.red }} />
                            </IconButton>
                        ) : null
                    }
                    title={<Typography textAlign={'left'} variant="subtitle1" fontWeight={600}>{reviewer_name || 'N/A'}</Typography>}
                    subheader={<Box display={'flex'}><Rating value={rating ?? 0} readOnly precision={0.5} /></Box>}
                />
                <CardContent style={{ padding: 15 }}>
                    {review_text &&
                        <Typography variant="body2" mt={1} color="gray" textAlign={'left'} >
                            {review_text}
                        </Typography>
                    }
                    {review_date &&
                        <Typography variant="body2" color="text.secondary" textAlign={'right'} fontSize={'small'}>
                            {formatDate(review_date)}
                        </Typography>
                    }
                </CardContent>
            </Card>

            {/* 🔴 Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                aria-labelledby="delete-review-dialog"
                PaperProps={{
                    sx: {
                        borderRadius: 3,
                    }
                }}
            >
                <DialogTitle sx={{ fontWeight: 600 }}>
                    ❌ Confirm Deletion
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" color="text.secondary">
                        🗑️ Say bye-bye! Delete this review for good? No take-backs! 😬
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ borderRadius: 4 }}
                        onClick={() => setDeleteDialogOpen(false)}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ borderRadius: 4, bgcolor: theme.colors.secondaryDark }}
                        onClick={() => { onDelete(); setDeleteDialogOpen(false); }}
                        variant="contained"

                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ReviewComponent;