import { Alert, Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Using GridV2
import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "../../../App.css";
import { signInUser } from '../../../services/authServices';
import { theme } from '../../../theme';
import { AppBarPadding, BottomPadding, checkSuccess } from '../../../utils/helperUtils';
import CustomInput from '../../components/CustomComponents';
import GoogleLoginComponent from '../../components/GoogleLoginComponent';
import { HelloWave } from '../../components/HelloWave';
import { useSnackbar } from '../../../context/SnackbarContext';
import { useAuth } from "../../../context/AuthContext"; // Import useAuth

import "../../../App.css"

function LoginView() {

  const navigate = useNavigate();
  const location = useLocation();

  const showSnackbar = useSnackbar(); 

  const { login } = useAuth(); 

  const from = location.state?.from || "/";

  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Handle Input Changes
  const handleChange = (field) => (value) => {
    setCredentials((prev) => ({ ...prev, [field]: value }));
  };

  // Handle Login
  const handleLogin = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await signInUser(credentials.email, credentials.password, login);
      if (checkSuccess(response)) {
        showSnackbar("🎉 Login successful!", "success");
        navigate(from, { replace: true });
      } else {
        setError(response.error || "Invalid login credentials");
      }
    } catch (err) {
      setError(err.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}>
        <Box className="view-container" bgcolor={theme.colors.primary}>
          <AppBarPadding />
          <Grid container justifyContent={'center'}>
            <Grid size={{ xs: 12, md: 10, lg: 6 }}>
              <Grid
                container
                spacing={2}
                bgcolor={theme.colors.background}
                p={2}
                borderRadius={5}
              >
                <Grid size={{ xs: 12 }}>
                  <Typography fontSize={30} ml={1}>
                    <Box sx={{ fontWeight: 'bold', }}>
                      Hi, Welcome! {<HelloWave />}
                    </Box>
                  </Typography>
                </Grid>
                {/* Show error alert if login fails */}
                {error && (
                  <Grid size={{ xs: 12 }}>
                    <Alert severity="error">{error}</Alert>
                  </Grid>
                )}

                <Grid size={{ xs: 12 }}>
                  <CustomInput
                    label="Email Address"
                    type="email"
                    placeholder="Enter your email"
                    value={credentials.email}
                    onChange={handleChange("email")}
                  />
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <CustomInput
                    label="Password"
                    type="password"
                    placeholder="Enter your password"
                    value={credentials.password}
                    onChange={handleChange("password")}
                  />
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Button
                    color='primary'
                    variant='contained'
                    fullWidth
                    size='large'
                    sx={{ borderRadius: 5 }}
                    onClick={handleLogin}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Log In 💎"}
                  </Button>
                  
                </Grid>
              </Grid>
              {/* Google Sign-In Button */}
              <Grid mt={2}>
                <GoogleLoginComponent
                  loading={loading}
                  setLoading={setLoading}
                  setError={setError}
                  from={from}
                />
              </Grid>

              {/* Divider */}
              <Grid>
                <Divider
                  sx={{
                    "&::before, &::after": { borderColor: "white" },
                    color: 'white',
                    my: 2
                  }}
                >
                  OR
                </Divider>
              </Grid>

              {/* Create Account Button */}
              <Grid>
                <Button
                  variant='contained'
                  fullWidth
                  size='large'
                  sx={{ borderRadius: 5, color: 'blanchedalmond', bgcolor: theme.colors.secondary }}
                onClick={() => navigate("/_/signup", { state: { from: from }})}
                >
                  🙌🏻 Create a new account
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <BottomPadding />

        </Box>
    </GoogleOAuthProvider>
  )
}

export default LoginView